import Vue from 'vue'
// Lib imports
import axios from 'axios'
var production = true
Vue.prototype.$app_version = localStorage._kake_system_version
Vue.prototype.$app_mode = "Development"
var ACCOUNTSERVER = "http://localhost:9002"
var BET_SERVER = "https://co-dev-serve.mastersgame.net"
if(production) {
  if(Vue.prototype.$app_mode == "Production")
    ACCOUNTSERVER = "https://srv.pro-atlas8.com"
  else if(Vue.prototype.$app_mode == "Development")
    ACCOUNTSERVER = "https://new-atlas-dev-serve.mastersgame.net"
}
Vue.prototype.$http = axios.create({
  baseURL: ACCOUNTSERVER + '/api/'
})
Vue.prototype.$multibet = axios.create({
  baseURL: BET_SERVER + '/api/v1/'
})

Vue.prototype.$fileserver = ACCOUNTSERVER + '/public/'