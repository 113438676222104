<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="370">
    <v-card >
        <v-card-text class="pa-4 subtitle-1" v-if="auth==false">
          <v-form autocomplete="off">
           <v-text-field
            v-model="text"
            class="lg-font"
            style="font-size: 20px; text-align: center;"
            type="number"
            label="Cashout points"
            hide-details
            outlined
            large
           />
          </v-form>
          <v-divider/>
        </v-card-text>
          <v-card-text class="pa-4 text-center" v-else>
                  <div class="lg-font mb-3"> For Cashout  <span class="text-h5 text-success">{{$money(text)}}</span> </div>
                  
            <v-divider/>
          </v-card-text>
        <v-card-actions>
                <v-btn color="warning"  text   @click="text ='close', emitToParent()">close</v-btn>
            <v-spacer></v-spacer>
             <v-btn large  color="success" :loading="loading" @click="validateAmount()" v-if="auth==true">
            
             Confirm</v-btn>
            <v-btn large  color="success"  @click="ValidateSender()" v-else>
               OK</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        sendbtn:"SEND",
         interval: {},
         loading: false,
         value:0,
         auth: false,
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    account: {
       type: Object,
        default: ()=>{}
    } 
  },
  watch:{
      show(val) {
          if(!val){
            this.text=''
            this.sendbtn = "SEND"
             this.auth=false
             this.loading = false
             clearInterval(this.interval)
        }
      }
  },
  computed:{
      icon() {
         switch(this.sendbtn) {
           case "SEND":
             return "database-arrow-right"
          case "Points sent":
             return "check"
          default: 
            return ""
         }
      },
      TO_ACCOUNT(){
        var id =0
        if(this.account.role == "Agent") {
            id = this.account.coordinator
        } else if(this.account.role == "Sub-Agent") {
            id = this.account.agent
        }else if(this.account.role == "Player") {
            id = this.account.sub_agent
        }
        return id
      },
  },
  methods: {
    ValidateSender(){
         if(this.$IsNum(this.text.trim())) {
            this.auth = true
      } else {
        this.VA_ALERT('error', "Enter points!")
      }
    },
    validateAmount(){
      if(this.$IsNum(this.text.trim())) {
              this.sendbtn = "Cancel sending"
              this.loading = true
              this.Send()
              // this.interval = setInterval(() => {
              //       if (this.value === 100) {
              //         this.value = 0
              //         clearInterval(this.interval)
              //          this.sendbtn = "Points sent"
              //         //this.emitToParent()
              //         return 
              //       }
              //       this.value += 5
              //     }, 400)
      } else {
        this.VA_ALERT('error', "Enter points!")
      }
    },
    Send() {
      // if(this.$IsNum(this.account.balance) < this.$IsNum(this.text.trim())) {
      //   this.VA_ALERT("error", "")
      //   return
      // }
        var param = {}
        param.type = "Cash Out"
        param.description = "Cashout "
        param.amount = this.$IsNum(this.text.trim())
       
        param.account = this.account.id
       console.log(param)
        this.$http.post("wallet/load",param).then(response => {
              if(response.data.status) {
                  this.VA_ALERT('success', response.data.message)
                  
                  this.emitToParent()
              } else {
                  this.VA_ALERT('error', response.data.message)
              }
              this.loading = false
          }).catch(e => {
            console.log(e.data)
          })

    },

    emitToParent () {
      this.$emit('DialogEvent', this.text.trim())
    }
  }
}
</script>
