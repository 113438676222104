import Vue from 'vue'
import { initializeApp } from "firebase/app";
import { getDatabase, ref, set, onValue  } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyD_mk_FyAET8DjpD14SzhpHyUyzMujncrE",
  authDomain: "multibet-6b7de.firebaseapp.com",
  databaseURL: "https://multibet-6b7de-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "multibet-6b7de",
  storageBucket: "multibet-6b7de.appspot.com",
  messagingSenderId: "44017614173",
  appId: "1:44017614173:web:7a7c81af18be08c8013cb4",
  measurementId: "G-5E9MP4GWET"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getDatabase(app);

Vue.prototype.$fbdb = db;
Vue.prototype.$fbdbref = ref;
Vue.prototype.$fbdbset = set;
Vue.prototype.$fbdbonValue = onValue;


Vue.prototype.$money = function (amount) {
  if (!amount) { amount = 0 }
  if (isNaN(amount)) {
    amount = 0
  }
  amount = parseFloat(amount).toFixed(2)
  return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}
Vue.prototype.$date_format = "YY-MMM-DD hh:mm A"
Vue.prototype.$IsNum = function (input) {
  if (isNaN(parseFloat(input)) || input == null) {
    return 0
  }
  input = parseFloat(input).toFixed(2)
  return parseFloat(input)
}
Vue.prototype.$NumPad = function (input, pad) {
  if (isNaN(parseFloat(input)) || input == null) {
    return 0
  }
  input = parseFloat(input).toFixed(pad)
  return parseFloat(input)
}
Vue.prototype.$printThis = function () {
    window.print()
}
Vue.prototype.$groupBy = key => array =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key]
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
    return objectsByKeyValue
  }, {})

  Vue.prototype.$phoneNum = function (inputtxt) {
    var phoneno = "09";
    if(inputtxt.length != 11) {
      return false
    }
    if((inputtxt.match(phoneno))) 
    {
        return true;
    }
  else
    {
    return false;
    }
}

