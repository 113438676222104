<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="380">
    <v-card color="secondary" dark>
      <v-toolbar dense  color="secondary"><span class="_headtext-md">BET AMOUNT</span></v-toolbar>
        <v-card-text class="pa-4 subtitle-1">
          <v-form autocomplete="off">
            <v-row no-gutters>
              <v-col cols="6" class="pa-1">
                <v-text-field
                  v-model="text"
                  class="lg-font"
                  style="font-size: 20px; text-align: center;"
                  label="STD"
                  hide-details
                  type="number"
                  outlined
                  @focus="text=''"
                  large
                />
              </v-col>
              <v-col cols="6" class="pa-1">
                <v-text-field
                  v-model="text2"
                  class="lg-font"
                  style="font-size: 20px; text-align: center;"
                  label="RBL"
                  hide-details
                  type="number"
                  outlined
                  @focus="text2=''"
                  large
                />
              </v-col>
          </v-row>
          </v-form>
        
              <!-- <div class="tex-center mt-3">
                   <v-text-field 810120
                      v-model="drawnum"
                      type="number"
                      class="lg-font"
                      style="font-size: 20px; text-align: center;"
                      label="No. of draws"
                      hide-details
                      outlined
                      @focus="drawnum=''"
                      dense
                    />
              </div> -->
            <v-row class="tex-center mt-3" no-gutters>
              <v-col cols="4" class="pa-1 text-center"  v-for="(item,index) in amounts" :key="index">
                <v-btn fab  large class="btn-coin" @click="addBet(item.toString())" >
                  <span class="_headtext-3">{{item}}</span>
                </v-btn>
              </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
                <v-btn color="warning"   class="_headtext-lg" text rounded  @click="text ='close', emitToParent()">close</v-btn>
            <v-spacer></v-spacer>
            <v-btn  large color="success"  class="_headtext-lg" rounded  @click="reValidateAmount()">ADD BET</v-btn>
        </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        text2: '',
        std: true,
        rbl: false,
        drawnum: 1,
        amounts: [5,10, 15, 20,30,50,100,150,200],
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    linetype: {
       type: String,
        default: ""
    },
    numarr: {
        type: Array,
        default: ()=>[]
    } 
  },
  watch:{
      show(val) {
          if(!val){
            this.text=''
            this.text2=''
            this.rbl = false
           this.std = true
          }
      }
  },
  methods: {
    addBet(amount){
        if(this.std) {
          this.text = amount
          this.rbl = true
          this.std = false
        //  this.validateAmount(amount)
        } else if(this.rbl) {
          this.text2 = amount
          this.rbl = false
          this.std = true
         // this.validateAmount(amount)
        }
    },
    validateAmount(amount){
      if(this.$IsNum(amount) < 5) {
        this.VA_ALERT('error', "Minimum bet is 5!")
        return
      }
      if(this.$IsNum(amount)) {
        if("RBL" == this.linetype) {
          var numcount = this.NUMCOMB(this.numarr, this.numarr.length)
          if(this.$IsNum(amount) % numcount) {
            this.VA_ALERT('error', "Rambol: Amount must be divisible by " +numcount+ "!")
            return
          }
        }
          if(this.$IsNum(amount) < 1) {
            this.VA_ALERT('error', "Bet limit is 1!")
            return
          }

        if(this.$IsNum(amount) > 1000) {
          this.VA_ALERT('error', "Entered amount reached the limit!")
        } 
      } else {
        this.VA_ALERT('error', "Enter Amount!")
      }
    },
    reValidateAmount() {
      let amount = this.text.trim()
      let amount2 = this.text2.trim()
      if(this.$IsNum(amount) < 5 && this.$IsNum(amount2) < 5) {
        this.VA_ALERT('error', "Minimum bet is 5!")
        return
      }
      if(this.$IsNum(amount) < 1 && this.$IsNum(amount2) < 1) {
        this.VA_ALERT('error', "Bet limit is 1!")
        return
      }
      if(this.$IsNum(amount2)) {
     
          var numcount = this.NUMCOMB(this.numarr, this.numarr.length)
          if(this.$IsNum(amount2) % numcount) {
            this.VA_ALERT('error', "Rambol: Amount must be divisible by " +numcount+ "!")
            return
          }
        
      }  
     

      if(this.$IsNum(amount) > 1000 || this.$IsNum(amount2) > 1000) {
        this.VA_ALERT('error', "Entered amount reached the limit!")
        return
      } 
      
      this.emitToParent() 
    },
    emitToParent () {
   
      var param = {}
      param.amount = [this.text.trim(), this.text2.trim()]
      this.$emit('DialogEvent', param)
    }
  }
}
</script>
