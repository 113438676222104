/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */
export default [
    {
      path: '/dashboard',
      name: 'Dashboard',
      view: 'Dashboard'
    },
    {
      path: '/login',
      name: 'Login',
      view: 'Login'
    },
    {
      path: '/play',
      name: 'Play RNG',
      view: 'Play'
    },
    {
      path: '/play-perya-antique',
      name: 'Peryahan Antique',
      view: 'GamePerya'
    },
    {
      path: '/play-stl-silay',
      name: 'STL Silay',
      view: 'GameSilay'
    },
    {
      path: '/play-2d-stl-iloilo-city',
      name: '2D STL Iloilo City',
      view: '2DGameCity'
    },
    {
      path: '/play-3d-stl-iloilo-city',
      name: '3D STL Iloilo City',
      view: '3DGameCity'
    },
    {
      path: '/play-stl-iloilo-Province',
      name: 'STL Iloilo Province',
      view: 'GameProvince'
    },
    {
      path: '/play-2d',
      name: 'Play 2D',
      view: 'Game2D'
    },
    {
      path: '/play-3d',
      name: 'Play 3D',
      view: 'Game3D'
    },
    {
      path: '/play-4d',
      name: 'Play 4D',
      view: 'Game4D'
    },
    {
      path: '/play-pick3',
      name: 'Play PICK3',
      view: 'GamePick3'
    },
    // {
    //   path: '/play-pick3',
    //   name: 'Play PICK3',
    //   view: 'Pick3'
    // },
    {
      path: '/account',
      name: 'Account',
      view: 'Account'
    },
    {
      path: '/results',
      name: 'Results',
      view: 'Results'
    },
    {
      path: '/sales',
      name: 'Ticket Sales',
      view: 'reports/Tickets'
    },
    {
      path: '/summary',
      name: 'Account Summary',
      view: 'reports/Summary'
    },
    {
      path: '/wallet',
      name: 'Wallet',
      view: 'reports/Wallet'
    },
    {
      path: '/claim',
      name: 'Claim Tickets',
      view: 'reports/Claim'
    },
    {
      path: '/buy-credits',
      name: 'Buy Credits',
      view: 'BuyCredits'
    },

    {
      path: '/commissions',
      name: 'Commissions Logs',
      view: 'reports/Commissions'
    },
    {
      path: '/players',
      name: 'Agents and Players',
      view: 'Players'
    },
    {
      path: '/play-games',
      name: 'Games',
      view: 'Games'
    },
    {
      path: '/number-games',
      name: 'Number Games',
      view: 'NumberGames'
    },
    {
      path: '/buy-process',
      name: 'Buy Process',
      view: 'BuyProcess'
    },
    {
      path: '/cashout',
      name: 'Cashout',
      view: 'Cashout'
    },
    {
      path: '/color-game',
      name: 'Color Game',
      view: 'ColorGame'
    },
    {
      path: '/register/:id',
      name: 'Registration',
      view: 'Registration'
    },
    {
      path: '/update',
      name: 'System Update',
      view: 'System'
    },
    
  ]
  