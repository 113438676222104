import '@mdi/font/css/materialdesignicons.css' // Ensure you are using css-loade
import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: "#f79c05",//"#FBAD16",//'#AC1F62',
            secondary:  '#0d570b',
            tertiary: '#0d580c',
            accent: '#AC1F62',
            error: '#f55a4e',
            info: '#00d3ee',
            gold:"#E38011",
            success: "#5cb860",//#5cb860',
            warning: '#ffa21a',
            wala: '#372DCC',
            meron: '#CC232A',
            jackpot: '#F4CA16',
            cancelled:"#ffa21a",
            draw:"#333",
          },
        },
      },
    icons: {
      iconfont: 'mdi', // default - only for display purposes
    },
});
