<template>

<v-dialog
    v-model="show"
    persistent
    max-width="380">
    <v-card tile color="secondary">
      <v-toolbar dense flat color="primary" dark>
        <span class="_headtext-md">Unavailable</span>
        <v-spacer/>
           <v-btn x-small fab rounded  @click="emitToParent('ok')"><v-icon>mdi-close</v-icon></v-btn>
      </v-toolbar>
        <v-card-text>
              <div class="_headtext text-center ma-10" color="white">This game will be available soon!</div>
        </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    show: {
        type: Boolean,
        default: false
    }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    }
  }
}
</script>
