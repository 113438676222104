<template>

  <v-dialog
    v-model="show"
    persistent
    max-width="380"
    >
    <v-card>
      <v-card-title>
        <v-icon left color="warning">mdi-database</v-icon> Buy Credits
      </v-card-title>
      <v-card-text class="text-center pt-4">
      
        <div class="text-h4 text-success">{{$money(amount)}}</div>
                  <div></div>
      </v-card-text>
     <v-card-actions>
      
        <v-btn color="warning" v-if="loading == false" @click="emitToParent('close')">Cancel</v-btn>
        <v-spacer/>
        <v-btn color="success" @click="proceed()" :loading="loading" >Proceed</v-btn>
     </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
    data: () => ({
      loading: false,
    }),
    components: {
   // VueQrcode,
  },
  props: {
    show: {
        type: Boolean,
        default: false
    },
    user: {
      type: Object,
      default: ()=>{}
    },
    amount: {
        type: Number,
        default: 0
    }
  },
  watch:{
    
  },
  computed: {
    CALCULATED(){
        //var fee = (this.$IsNum(this.amount) * 0.02) + 10
       return this.amount
    }
  },
  methods: {
    emitToParent (action) {
      this.$emit('DialogEvent', action)
    },
    clear() {
      
    },
    proceed() {
      this.loading = true
      var req = {}
      req.req_amount  = this.$IsNum(this.amount)// this.$NumPad(new_amt.toString() + "00", 4)
      req.Name = this.user.username
      console.log(req)

      this.$http.post("buy_credits", req).then(response => {
                console.log(response.data)
                this.loading = false
                if(response.data.status) {
                  localStorage.buy_code = response.data.transcode
                  console.log(response.data.response.url)
                  var gcashurl = response.data.response.url
                 
                  window.open(gcashurl)
                  this.$emit('DialogEvent', "close")
                } else {
                  this.VA_ALERT("error", "Service not available at this moment!")
                }
               
               }).catch(e => {
                console.log(e.data)
                this.loading = false
            })
    }
  }
}
</script>
