<template>
  <v-app>
    <v-main :class="bgclass">
      <core-toolbar  v-if="IsLoggedIn"/>
      <core-drawer v-if="IsLoggedIn"/>
      <core-view />
      <core-footer v-if="$route.name != 'Login'"/>
    </v-main>
       <v-snackbar
      v-model="showAlert"
      :color="alertColor"
      :timeout="3000"
       :top="'top'"
     >
     <div class="text-center text-h6 mt-1 mb-1">
        {{alertText }}
     </div>
    </v-snackbar>
     <v-overlay :value="LOADING">
      <p class="text-center">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
        <br/>
        <i>{{LOADING_TEXT}}</i>
      </p>
    </v-overlay>
    <!-- <va-machine :show="OPENMACHINE" @DialogEvent="mEvent"/> -->
  </v-app>
</template>

<script>
import {
  mapMutations
} from 'vuex'
export default {
  name: 'App',
  components: {
  },

  data: () => ({
  }),
  mounted () {
    //this.onResponsiveInverted()
    //window.addEventListener('resize', this.onResponsiveInverted)
  },
  computed:{
    WATCHDRAW(){
      if(this.$route.name != "Login"){
         return this.$store.state.watchdraw
      } else {
        return false
      }
    },
    OPENMACHINE(){
      return this.$store.state.open_machine
    },
    LOADING(){
      return this.$store.state.overlay_loading
    },
     LOADING_TEXT(){
      return this.$store.state.overlay_loading_text
    },
      showAlert: {
        get () {
          return this.$store.state.show_alert
          },
          set (val) {
            this.setAlert(val)
          }
      },
      alertText() {
        return this.$store.state.alert_text
      },
      alertColor(){
          return this.$store.state.alert_color
      },
      windowClass() {
        if(this.$store.state.responsive){
          return "va-reponsive"
        } else {
          return "va-not-reponsive"
        }
      },
      IsLoggedIn(){
         return this.$store.state.is_logged
      },
      bgclass() {
          if(this.$store.state.is_logged){
            return "logged-page"
          } else {
            return "login-page"
          }
      }
  },
  methods: {
    ...mapMutations(['setDrawer', 'setResponsive', 'setAlert', 'setOpenMachine', "setWatchDraw"]),
     mEvent() {
        this.setOpenMachine(false)
     }
  }

};
</script>
<style>
/* file:///android_asset/www/ */
/* .logbox {
  background: #0B3447 url('/img/banner-img.png') no-repeat center bottom;; 
} */
@font-face {
            font-family: PotatoSans-Bold;
            src: url("/fonts/Potato_sans-Bold.otf");
          }
          @font-face {
            font-family: PotatoSans-Black;
            src: url("/fonts/Potato_sans-Black.otf");
          }

          #myiframe {
      width: 100%;
      height: 100%;
   }
#va-footernav {
  border-radius: 40px;
    background-color: #fff;
}
 .logged-page  {
    background: url('/img/background.png') repeat center center; 
  } 
  .login-page {
    background:  url('/img/background.png') repeat center center; 
    background-size: 100% 100%;
  }
  #va-drawer, #va-toolbar {
    text-transform: uppercase !important;
    font-family: 'Montserrat', sans-serif !important;
  }
   #va-drawer {
     z-index: 9;
   }
  .va-table {
      width: 100%;
  }
  .va-not-reponsive {
    padding-left: 255px;
  }
  .va-table tbody td {
    font-size: .8em !important;
  }
  @media print {
    
    .va-table  thead td {
    font-size: .45em !important;
  }
   .va-table  tbody td {
    font-size: .55em !important;
    height: 10px !important;
  }
   .va-table  tfoot td {
    font-size: .45em !important;
  }
}
  .va-underline {
    border-bottom: 1px solid #F96332;
  }
  .v-select__slot, .v-text-field__slot {
      font-size: .75em !important;
  }
  .v-btn__content {
    padding-left: 8px !important;
    padding-right: 8px !important;
  }
  .va-right input{
    text-align: right;
  }
  .va-input {
     width: 70px !important;
     background: #efefef;
     text-align: center;
  }
.sw-bg-green .v-input__control {
  
}
.text-white {
  color: white;
}
.text-secondary {
  color: #0d570b;
}
.text-success {
  color: #5cb860;
}
.text-error {
  color: #f55a4e;
}
.text-gold {
  color: #f7a61d;
}
.text-primary {
  color: #0F5D0D;
}
.text-warning {
  color: #ffa21a;
}
.text-info {
  color: #00d3ee;
}
.text-accent {
  color: #82B1FF;
}
.icon-text {
  color: #fff;
  font-size: .7em !important;
}
.img-chips img {
  width: 42px;
  height: 42px;
  opacity: .5;
  transition: width 0.2s, height 0.2s;
}
.img-chips img.select_amt {
  width: 48px;
  height: 48px;
  opacity: 1;
}
.inner-table td {
  border-bottom: 0 !important;
  width: 50px !important;
  text-align: center;
  border-right:  1px solid #eee;
}

/* Drawer */
#va-drawer .v-expansion-panel-content__wrap {
  padding: 0 !important;
}
#va-drawer .v-expansion-panels .v-expansion-panel {
  background: none !important;
  /* border-radius: 0 !important;
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;  */
}
#va-drawer .v-expansion-panel::before {
  -webkit-box-shadow:none !important; 
   box-shadow: none !important;

}
.lg-font {
  font-family: 'OpenSans-Bold';
  font-size: 1.3em;
}
.sm-font {
  font-size: .7em;
}
.md-font {
  font-size: .8em;
}

.print_table3  td {
  border-bottom: 1px solid #eee;
  padding: 12px;
}
.print_table3 .inner-table  td {
  padding: 0;
}
.div-header {
  background: #5EAD79;
  color:#fff;
  padding: 4px 10px;
}
.pos .v-expansion-panel-content__wrap {
    padding: 7px;;
}
.text-shadow {
  text-shadow: 2px 2px #232323;
} 
.td-odd {
  background: #f0f0f0;
}
.td-even {
  background: #ddd;
}
.regla_container {
  width: 100%;
  padding: 4px;
  overflow: scroll;
}
.regla_wrap {
  width: 1280px;
}
.regla {
  width: 48px;
  float: left;
  border: 1px solid #fff;
  background: #f1f1f1;
  padding: 3px;
  text-align: center;
}
._numbtn {
  background: url('/img/bg-ball.png') repeat center center; 
  background-size: 100%;
  /* background: rgb(13,87,11);
background: linear-gradient(90deg, rgba(13,87,11,1) 0%, rgba(232,229,223,1) 0%, rgba(247,166,29,1) 58%);
  border: 2px 1px solid #000; */
  
}
._headtext {
  font-family:  PotatoSans-Black !important;
   text-shadow: 0 0 3px #000, 0 0 5px #000; 
  color: #fff;
  font-size: 1.6em;
}

._headtext-md {
  font-family:  PotatoSans-Black !important;
   text-shadow: 0 0 3px #000, 0 0 5px #000; 
  font-size: 1em;
}
._text_shadow {
  text-shadow: 0 0 3px #000, 0 0 5px #000; 
}
._headtext-lg {
  font-family:  PotatoSans-Black !important;
   text-shadow: 0 0 1px #000, 0 0 2px #000; 
  font-size: 2.6em;
}
._headtext-2 {
  font-family:  PotatoSans-Black !important;
   text-shadow: 0 0 2px #000, 0 0 3px #000; 
  color: #fff;
  font-size: 1.1em;
}
._headtext-3 {
  font-family:  PotatoSans-Black !important;
   text-shadow: 0 0 2px #000, 0 0 3px #000; 
  font-size: 1.8em;
}

._numtext {
  /* font-family:  PotatoSans-Black !important; */
   /* text-shadow: 0 0 3px #000, 0 0 5px #000;  */
  /* text-shadow: 2px 2px 2px #000; */
  font-weight: bolder !important;
  font-size: 2em;
}
.btn-amt {
  background:  url('/img/greenball.png') no-repeat center center;
  background-size: 100% 100%;
}
.btn-coin {
  background:  url('/img/gold.png') no-repeat center center;
  background-size: 100% 100%;
}
.rescard {
  background:  url('/img/ngbg.png') no-repeat center top; 
  background-color: #0d570b !important;
}
         
.btn-grad-primary {
            background-image: linear-gradient(to right, #FF512F 0%, #F09819  51%, #FF512F  100%);
            /* margin: 10px;
            padding: 15px 45px; */
            text-align: center;
            text-transform: uppercase;
            transition: 0.5s;
            background-size: 200% auto;
            color: white;            
            box-shadow: 0 0 20px #eee;
            border-radius: 10px;
            display: block;
          }

          .btn-grad-primary:hover {
            background-position: right center; /* change the direction of the change here */
            color: #fff;
            text-decoration: none;
          }
         
</style>