<template>

  <v-dialog
    v-model="show"
    persistent
    fullscreen>
    <v-card >
      <v-toolbar dense  color="secondary" dark>
           <v-icon color="warning" @click="text ='close', emitToParent('close')" left>mdi-arrow-left</v-icon>{{data.fullname}}
          <v-spacer/>
         <span style="width: 140px; margin-top: 24px;"> 
                     <v-menu
                        v-model="to_menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field 
                              label="Date"
                              :value="to_date"
                              readonly
                              filled
                              hide-detail
                              dense
                              outlined
                              append-icon="mdi-calendar"
                              v-on="on"
                            />
                        </template>
                        <v-date-picker v-model="to_date" no-title scrollable @input="to_menu=false, getTransactions()" />
                    </v-menu>
               </span>
      </v-toolbar>
        <v-card-text class="pa-4">
            <span class="text-info"><v-icon color="warning" left>mdi-clipboard-list-outline</v-icon> Transactions</span>
              <v-divider  class="mb-5"/>
                  <table class="va-table def-font mb-4" id="account-trans-table">
                <tr>
                    <!-- <th>Date Time</th> -->
                    <th  class="text-left border_bot pa-1">Type</th>
                    <th  class="text-left border_bot pa-1">Description</th>
                    <th class="text-right border_bot pa-1">Amount</th>
                   <th class="text-right border_bot pa-1">Prev. Bal</th>
                    <th class="text-right border_bot pa-1">Cur. Bal</th>
                </tr>
                 <tr v-for="(item,index) in transactions" :key="index" >
                    <!-- <td class="border_bot">{{$moment(item.transdt).format("MMM DD, YYYY")}} </td> -->
                    <td class="border_bot pa-1" :class="'td-'+IS_ODD(index)">{{item.type}}
                        <br />
                        <small>{{$moment(item.transdt).format("MMM DD, YYYY HH:mm:ss A")}}</small>
                    </td>
                    <td class="border_bot pa-1" :class="'td-'+IS_ODD(index)"><small>{{item.description}}</small></td>
                      <td class="text-right border_bot pa-1" :class="'td-'+IS_ODD(index)">{{$money(item.amount)}}</td>
                     <td class="text-right border_bot pa-1" :class="'td-'+IS_ODD(index)">{{$money(item.prev_balance)}}</td>
                     <td class="text-right border_bot pa-1" :class="'td-'+IS_ODD(index)">{{$money(item.cur_balance)}}</td>
                </tr>
            </table>
        </v-card-text>
        
        
    </v-card>
  </v-dialog>
</template>

<script>
export default {
    data: () => ({
        text: '',
        valid: true,
        to_menu: false,
      to_date:'',
      transactions:[],
        user:{},
         nameRules: [
            v => !!v || '*Required'
        ],
    }),
  props: {
    show: {
        type: Boolean,
        default: false
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  watch:{
      show(val) {
          if(val){
            this.transactions = []
            this.to_date = this.$moment().format("YYYY-MM-DD")
            this.getTransactions()
         }
      }
  },
  computed: {
    
  },
  methods: {
   emitToParent (action) {
      this.$emit('DialogEvent', {action: action, text:this.text.trim()})
    }, 
    getTransactions() {
              this.$http.post("wallet/get_transactions", {account: this.data.id, from_date: this.to_date, to_date: this.to_date}).then(response => {
           response.data.items != null?this.transactions = response.data.items:this.transactions = []
           //console.log(response.data.items)
         }).catch(e => {
           console.log(e.data)
         })
    }
  }
}
</script>
