<template>

    <v-dialog
      v-model="show"
      persistent>
      <v-card>
        <v-toolbar   dense >
           <v-icon left>mdi-check</v-icon> Confirm Your Bet
        </v-toolbar>
          <v-card-text class="text-h5 pt-8 pb-8 text-center">
           {{$money(amount)}} to <span style="padding: 0 7px; margin: 0 3px; color:#fff" :class="bet_to.toLowerCase()">{{bet_to}}</span>?
           
          </v-card-text>
          <v-card-actions>
                  <v-btn tile @click="emitToParent('cancel')">Not Now</v-btn>
              <v-spacer></v-spacer>
              <v-btn tile  color="success" dark @click="emitToParent('ok')">Place Bet</v-btn>
          </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  export default {
    props: {
      show: {
          type: Boolean,
          default: false
      },
      okbtn:{
           type: String,
          default: ""
      },
      cancelbtn:{
           type: String,
          default: ""
      },
      bet_to: {
          type: String,
          default: ""
      },
       amount: {
          type: Number,
          default: 0
      }
    },
    watch:{
      show(v) {
        if(v) {
          if(this.amount == 0) {
            this.VA_ALERT("warning", "Please Enter amount!")
            this.$emit('DialogEvent', "cancel")
          }
        }
      }
    },
    methods: {
      emitToParent (action) {
        this.$emit('DialogEvent', action)
      }
    }
  }
  </script>
  